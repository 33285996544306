<template>
  <div style="height: 100vh">
    <div v-html="transaction.form"></div>
  </div>
</template>

<script>
import { TransactionService } from '@/services'

export default {
  name: 'TWC',
  data () {
    return {
      transaction: {},
      loading: false
    }
  },
  mounted () {
    const html = document.getElementsByTagName('html')[0]
    html.classList = []
    this.init()
  },
  methods: {
    init () {
      TransactionService.check(this.$route.params.id)
          .then(({ data }) => {
            this.transaction = data
            setTimeout(() => {
              document.getElementById("the-form").action ="https://setmpos.ykb.com/3DSWebService/YKBPaymentService";
              const form = document.getElementById('the-form');

              const hiddenInput1 = document.createElement('input');
              hiddenInput1.type = 'hidden';
              hiddenInput1.name = 'name';
              hiddenInput1.value = this.transaction.name;

              const hiddenInput2 = document.createElement('input');
              hiddenInput2.type = 'hidden';
              hiddenInput2.name = 'surname';
              hiddenInput2.value = this.transaction.surname;

              form.appendChild(hiddenInput1);
              form.appendChild(hiddenInput2);
              form.submit();
            }, 100)
          })
    }
  },
}
</script>
